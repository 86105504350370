import logo from "./logo.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import Legal from "./legal/Legal";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import DisclaimerModal from "./DisclaimerModal/DisclaimerModal";
import {
  
  useDisclosure,
  
} from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#050a02", // Set the background color here
      },
    },
  },
});

function App() {

    // const { isOpen, onOpen, onClose } = useDisclosure();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },

    {
      path: "/legal",
      element: <Legal />,
    },

    {
      path: "/disclaimer_trading",
      element: <Home />,
    },
  ]);
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
