import React, { useEffect, useRef, useState } from "react";
import styles from "./HowYWorks.module.css";
import ResponsiveVideoPlayer from "../VideoPlayer/VideoPlayer";


export default function HowYWorks({ id }) {

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.GradientL}></div>
        <div className={styles.GradientR}></div>
        <div className={styles.textContainer}>
          <div className={styles.logoContainer}>
            <img src="/images/headerLogo.svg" alt="Logo" />
          </div>
          <div className={styles.mainHeading}>
            How <span>Ÿ</span> Works
          </div>
          <div id={id} className={styles.subHeading}>
            The oracle is a multi model framework with intelligent market
            observer and predictor systems.
          </div>
        </div>
        <div className={styles.video}>
          <ResponsiveVideoPlayer videoSrc="https://yoracle.ai/videos/framework.mp4" />
        </div>
      </div>
    </div>
  );
}
