import React, { useState, useEffect, useRef } from "react";
import styles from "./GetStarted.module.css";
import { Link } from "react-router-dom";

export default function GetStarted() {
  

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.GradientL}></div>
        <div className={styles.GradientR}></div>
        <div className={styles.heading}>
          <h1>Get Started with</h1>
          <h1>Ÿ Data solutions.</h1>
          <p>Launch Products with Ÿ Oracle as your backbone.</p>
        </div>
        <div className={styles.buttonContainer}>
          <Link to={"https://dashboard.yoracle.ai"} className={styles.button1}>
            Ÿ Oracle
          </Link>

          <Link to={"https://twitter.com/yai_erc"} className={styles.button2}>
            API (Coming Soon)
          </Link>
        </div>
        <div className={styles.LogoContainer}>
          <img src="/images/headerLogo.svg" />
        </div>

        <div className={styles.linkContainer}>
          <Link to="https://twitter.com/yai_erc" className={styles.linkItem}>
            Twitter
          </Link>
          <Link to="https://t.me/YAIofficial" className={styles.linkItem}>
            Telegram
          </Link>
          <Link to="https://docs.yoracle.ai/" className={styles.linkItem}>
            Documentation
          </Link>
          <Link to="/legal" className={styles.linkItem}>
            Legal
          </Link>
          <Link to="/" className={styles.linkItem}>
            API
          </Link>
        </div>

        <div className={styles.contractAddress}>
          CA: 0x477a3d269266994f15e9c43a8d9c0561c4928088
        </div>
        <div className={styles.copyright}>
          Copyright © Y 2024. All rights reserved
        </div>
      </div>
    </div>
  );
}
