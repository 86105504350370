import React, { useState, useEffect, useRef } from "react";
import Hero from "./Hero/Hero";
import styles from "./Home.module.css";
import HowYWorks from "./HowYWorks/HowYWorks";
import RichOracle from "./RichOracle/RichOracle";
import Network from "./Network/Network";
import GetStarted from "./GetStarted/GetStarted";
import DataYouCanTrust from "./DataYouCanTrust/DataYouCanTrust";
import Modal from "./Modal";
import LocationModal from "./LocationModal";
import Scroll from "./Scroll/Scroll";
import DisclaimerModal from "./DisclaimerModal/DisclaimerModal";
import { useDisclosure } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import RealtimeAiPowered from "./RealtimeAiPowered/RealtimeAiPowered";
import Products from "./Products/Products";

function Home() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/disclaimer_trading") {
      onOpenDisclaimerModal();
      return;
    }
    onCloseDisclaimerModal();
  }, [location]);

  const handleNavigate = () => {
    navigate("/");
  };

  const {
    isOpen: isOpenDisclaimerModal,
    onOpen: onOpenDisclaimerModal,
    onClose: onCloseDisclaimerModal,
  } = useDisclosure();

  return (
    <div className={styles.mainContainer}>
      <Hero />
      <RealtimeAiPowered />

      <Products id="products" />
      <HowYWorks id="how-it-works" />
      <DataYouCanTrust id="" />

      <RichOracle />

      <Network id="tokenomics" />

      <GetStarted />

      <Modal />
      <LocationModal />
      <Scroll />
      <DisclaimerModal
        isOpen={isOpenDisclaimerModal}
        onClose={handleNavigate}
      />
    </div>
  );
}

export default Home;
