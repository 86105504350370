import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader/Loader";
import styles from "./VideoPlayer.module.css";

const ResponsiveVideoPlayer = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInViewport(true);
            if (!isLoading) {
              videoRef.current.play().catch((error) => {
                console.error("Error attempting to play video:", error);
              });
            }
          } else {
            setIsInViewport(false);
            videoRef.current.pause();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isLoading]);

  const handleCanPlay = () => {
    setIsLoading(false);
    if (isInViewport) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play video:", error);
      });
    }
  };

  const handleWaiting = () => {
    setIsLoading(true);
  };

  return (
    <div className={styles.videoContainer}>
      {isLoading && <Loader />}
      <video
        ref={videoRef}
        onCanPlay={handleCanPlay}
        onWaiting={handleWaiting}
        playsInline
        loop
        muted
        width="100%"
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default ResponsiveVideoPlayer;
