

// import React, { useState, useEffect, useRef } from "react";
// import styles from "./Network.module.css";
// import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
// import "./slider.css";
// import { Pagination, Autoplay } from "swiper/modules";

// const statsDataNetwork = [
//   { label: "Holders", value: 6000, unit: "", prefix: "+" },
//   { label: "Liquidity Lock", value: 2, unit: "years", prefix: "" },
//   { label: "Liquidity Provision", value: 1.3, unit: "M", prefix: "+" },
// ];

// export default function Network({ id }) {
//   const [stats, setStats] = useState(
//     statsDataNetwork.map((stat) => ({ ...stat, currentValue: 0 }))
//   );

//   const statsRef = useRef(null);
//   const [isVisible, setIsVisible] = useState(false);

//   const animateValue = (start, end, duration, index) => {
//     let startTime = null;
//     const step = (timestamp) => {
//       if (!startTime) startTime = timestamp;
//       const progress = Math.min((timestamp - startTime) / duration, 1);

//       setStats((prevStats) => {
//         const newStats = [...prevStats];

//         // Handle float values
//         const value = start + progress * (end - start);
//         const roundedValue =
//           end % 1 !== 0 ? value.toFixed(1) : Math.floor(value);

//         newStats[index] = {
//           ...newStats[index],
//           currentValue: roundedValue,
//         };

//         return newStats;
//       });

//       if (progress < 1) {
//         requestAnimationFrame(step);
//       }
//     };
//     requestAnimationFrame(step);
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         const entry = entries[0];
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//           observer.disconnect();
//         }
//       },
//       { threshold: 1 } // Trigger when 50% of the element is in the viewport
//     );

//     if (statsRef.current) {
//       observer.observe(statsRef.current);
//     }

//     return () => {
//       if (statsRef.current) {
//         observer.unobserve(statsRef.current);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     if (isVisible) {
//       stats.forEach((stat, index) => {
//         animateValue(0, stat.value, 2000, index);
//       });
//     }
//   }, [isVisible]);

//   return (
//     <div id={id} className={styles.mainContainer}>
//       <img className={styles.badge} src="/images/badge.png" alt="Badge" />
//       <img
//         className={styles.badgeM}
//         src="/images/badgeM.png"
//         alt="Badge Mobile"
//       />

//       <div className={styles.subContainer}>
//         <div className={styles.headingContainer}>
//           <div className={styles.mainHeading}>$YAI Token Network: ETH</div>

//           <div className={styles.bottomContainer}>
//             <div className={styles.Cta}>
//               <div className={styles.title}>User access</div>
//               <div className={styles.desc}>
//                 Available as hold to use and subscription access
//               </div>
//               <Link
//                 to="https://dashboard.yoracle.ai"
//                 className={styles.button1}
//               >
//                 Get Access
//               </Link>
//             </div>

//             <div className={styles.Cta}>
//               <div className={styles.title}>Builders</div>
//               <div className={styles.desc}>
//                 Enterprise Grade solutions to fit your company's data needs.
//               </div>
//               <Link to="https://t.me/YAISupportBot" className={styles.button2}>
//                 Contact Us
//               </Link>
//             </div>
//           </div>

//           <div className={styles.stats} ref={statsRef}>
//             <div className={styles.statsContainerBorder}>
//               <div className={styles.statsContainer}>
//                 {stats.map((stat, index) => (
//                   <React.Fragment key={stat.label}>
//                     {index > 0 && <div className={styles.spacer}></div>}
//                     <div className={styles.statss}>
//                       <h1>
//                         {stat.prefix}
//                         {stat.currentValue}
//                         <span
//                           className={
//                             stat.label === "Liquidity Lock" ? styles.spanY : ""
//                           }
//                         >
//                           {stat.unit}
//                         </span>{" "}
//                       </h1>
//                       <p>{stat.label}</p>
//                     </div>
//                   </React.Fragment>
//                 ))}
//               </div>
//             </div>
//           </div>

//           <div className={styles.sliderCont} >
//             <div className={styles.slider}>
//               <Swiper
//                 slidesPerView={1}
//                 autoplay={{
//                   delay: 3500,
//                   disableOnInteraction: false,
//                 }}
//                 pagination={{
//                   clickable: true,
//                 }}
//                 modules={[Pagination, Autoplay]}
//               >
//                 {stats.map((stat) => (
//                   <SwiperSlide key={stat.label}>
//                     <div className={styles.sliderBorder}>
//                       <div className={styles.smallSlider}>
//                         <h1>
//                           {stat.prefix}
//                           {stat.currentValue}
//                           <span
//                             className={
//                               stat.label === "Liquidity Lock"
//                                 ? styles.spanY
//                                 : ""
//                             }
//                           >
//                             {stat.unit}
//                           </span>{" "}
//                         </h1>
//                         <p>{stat.label}</p>
//                       </div>
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useState, useEffect, useRef } from "react";
import styles from "./Network.module.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "./slider.css";
import { Pagination, Autoplay } from "swiper/modules";
import { statsDataNetwork } from "../data";

export default function Network({ id }) {
  const [stats, setStats] = useState(
    statsDataNetwork.map((stat) => ({ ...stat, currentValue: 0 }))
  );

  const desktopStatsRef = useRef(null);
  const mobileStatsRef = useRef(null);
  const [isDesktopVisible, setIsDesktopVisible] = useState(false);
  const [isMobileVisible, setIsMobileVisible] = useState(false);

  const animateValue = (start, end, duration, index) => {
    let startTime = null;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);

      setStats((prevStats) => {
        const newStats = [...prevStats];
        const value = start + progress * (end - start);
        const roundedValue =
          end % 1 !== 0 ? value.toFixed(1) : Math.floor(value);
        newStats[index] = { ...newStats[index], currentValue: roundedValue };
        return newStats;
      });

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  };

  useEffect(() => {
    const observerDesktop = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsDesktopVisible(true);
          observerDesktop.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    const observerMobile = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsMobileVisible(true);
          observerMobile.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (desktopStatsRef.current) {
      observerDesktop.observe(desktopStatsRef.current);
    }

    if (mobileStatsRef.current) {
      observerMobile.observe(mobileStatsRef.current);
    }

    return () => {
      if (desktopStatsRef.current) {
        observerDesktop.unobserve(desktopStatsRef.current);
      }
      if (mobileStatsRef.current) {
        observerMobile.unobserve(mobileStatsRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isDesktopVisible || isMobileVisible) {
      stats.forEach((stat, index) => {
        animateValue(0, stat.value, 2000, index);
      });
    }
  }, [isDesktopVisible, isMobileVisible]);

  return (
    <div id={id} className={styles.mainContainer}>
      <img className={styles.badge} src="/images/badge.png" alt="Badge" />
      <img
        className={styles.badgeM}
        src="/images/badgeM.png"
        alt="Badge Mobile"
      />

      <div className={styles.subContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.mainHeading}>$YAI Token Network: ETH</div>

          <div className={styles.bottomContainer}>
            <div className={styles.Cta}>
              <div className={styles.title}>User access</div>
              <div className={styles.desc}>
                Available as hold to use and subscription access
              </div>
              <Link
                to="https://dashboard.yoracle.ai"
                className={styles.button1}
              >
                Get Access
              </Link>
            </div>

            <div className={styles.Cta}>
              <div className={styles.title}>Builders</div>
              <div className={styles.desc}>
                Enterprise Grade solutions to fit your company's data needs.
              </div>
              <Link to="https://t.me/YAISupportBot" className={styles.button2}>
                Contact Us
              </Link>
            </div>
          </div>

          <div className={styles.stats} ref={desktopStatsRef}>
            <div className={styles.statsContainerBorder}>
              <div className={styles.statsContainer}>
                {stats.map((stat, index) => (
                  <React.Fragment key={stat.label}>
                    {index > 0 && <div className={styles.spacer}></div>}
                    <div className={styles.statss}>
                      <h1>
                        {stat.prefix}
                        {stat.currentValue}
                        <span
                          className={
                            stat.label === "Liquidity Lock" ? styles.spanY : ""
                          }
                        >
                          {stat.unit}
                        </span>{" "}
                      </h1>
                      <p>{stat.label}</p>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.sliderCont} ref={mobileStatsRef}>
            <div className={styles.slider}>
              <Swiper
                slidesPerView={1}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
              >
                {stats.map((stat) => (
                  <SwiperSlide key={stat.label}>
                    <div className={styles.sliderBorder}>
                      <div className={styles.smallSlider}>
                        <h1>
                          {stat.prefix}
                          {stat.currentValue}
                          <span
                            className={
                              stat.label === "Liquidity Lock"
                                ? styles.spanY
                                : ""
                            }
                          >
                            {stat.unit}
                          </span>{" "}
                        </h1>
                        <p>{stat.label}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}