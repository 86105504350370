import { useState, useEffect, useRef } from "react";
import useIPDetector from "./IpDetector.js";
import {
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
} from "@chakra-ui/react";
import styles from "./LocationModal.module.css";

const LocationModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [accepted, setAccepted] = useState(false);
  const joinRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);

  const redirectToURL = () => {
    // Redirect to the specified URL
    window.location.href = "https://www.yoracle.ai/legal";
  };
  const countryCode = useIPDetector();
  // console.log("Detected Country Code:", countryCode);

  useEffect(() => {
    // const RestrictedUserConsent = localStorage.getItem("userRestricted");
    if (["US", "AE"].includes(countryCode)) {
      setShowPopup(true);
    }
  }, [countryCode]);

  // console.log(showPopup);

  return (
    <>
      {showPopup && (
        <Modal
          isOpen={true}
          onClose={onClose}
          // boxShadow={"none !important"}
        >
          <ModalOverlay
            bg={"rgba(0, 0, 0, 0.7)"}
            // boxShadow={"none !important"}
          />
          <ModalContent
            bg="radial-gradient(73.86% 104.92% at 50.1% 14.77%, rgba(16, 24, 43, 1) 3.13%, rgba(15, 20, 32, 1) 100%)"
            color="white"
            paddingX={["10px,30px,30px,30px"]}
            paddingY={["20px"]}
            alignItems={"center"}
            width="80%"
            maxW="400px"
            top={"20%"}
          >
            <ModalHeader>RESTRICTED REGION</ModalHeader>
            <ModalBody opacity={0.8}>
              Your IP is from a restricted area where Ÿ Project does not provide
              services. Please read &nbsp;
              {/* <div ref={joinRef}> */}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={redirectToURL}
                ref={joinRef}
              >
                https://www.yoracle.ai/legal
              </span>
              {/* </div> */}
            </ModalBody>

            <ModalFooter
              // boxShadow={"none !important"}
              width={"100%"}
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <span className={styles.subText}>
                If you are using a VPN, please disable it.
              </span>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default LocationModal;
