import React, { useEffect, useRef, useState } from "react";
import styles from "./RichOracle.module.css";
import Loader from "../Loader/Loader";
import ResponsiveVideoPlayer from "../VideoPlayer/VideoPlayer";

export default function RichOracle() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        });
      },
      { threshold: 1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);

      videoRef.current.addEventListener("play", () => {
        setIsPlaying(true);
      });

      videoRef.current.addEventListener("pause", () => {
        setIsPlaying(false);
      });
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
        videoRef.current.removeEventListener("play", () => setIsPlaying(true));
        videoRef.current.removeEventListener("pause", () =>
          setIsPlaying(false)
        );
      }
    };
  }, []);

  const handleCanPlay = () => {
    setIsLoading(false);
  };

  const handleWaiting = () => {
    setIsLoading(true);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.content}>
        <div className={styles.GradientL}></div>
        <div className={styles.GradientR}></div>
        <div className={styles.border}>
          <div className={styles.subContainer}>
            <div className={styles.sideImg}>
              <img src="/images/oracle.svg" alt="" />
            </div>
            <div className={styles.heading}>
              <h1>
                Rich & <br /> growing oracle
              </h1>
            </div>
            <div className={styles.videContainer}>
              <div className={styles.video}>
                <ResponsiveVideoPlayer
                  videoSrc="/images/growing_oracle.mp4"
                  placeholderSrc="/images/howOverlay.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
